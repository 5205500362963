import { reaction } from "mobx";

export default class Search {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    import("../graph").then(({ watchQuery }) => {
      this.watchQuery = watchQuery;
    });
    //
    let suggestionBoxTimeout;
    // for search suggestions, allow user clicks to proceed before unmounting
    reaction(
      () => this.isFocused,
      // hold open Search Suggestions, allowing click events to register before blur
      isFocused => {
        if (isFocused === null) {
          suggestionBoxTimeout = setTimeout(this.set.isFocused, 300, false);
        } else if (isFocused) {
          clearTimeout(suggestionBoxTimeout);
        }
      }
    );
    // for search suggestions, when no user input, clear entity preview
    reaction(
      () => this.hasQuery === false,
      noUserInput => {
        if (noUserInput && this.suggestedEntityInFocus) {
          this.set.suggestedEntityInFocus();
        }
      }
    );

    let debuffSuggestions;
    // for search suggestions, as user types, suggest entities
    reaction(
      () => [this.hasFilters, this.queryVariables.query],
      ([hasFilters, query]) => {
        clearTimeout(debuffSuggestions);

        if (hasFilters || this.hasQuery) {
          this.set.loadingSuggestions(true);

          debuffSuggestions = setTimeout(async () => {
            try {
              const limit = 4;

              await Promise.all([
                this.searchPapers({ query, limit }).then(
                  this.set.suggestedPapers
                ),
                Promise.all(
                  ["Tasks", "Videos", "Models"].map(entity =>
                    this.query({
                      query,
                      limit,
                      gqlQuery: `search${entity}`
                    }).then(this.set[`suggested${entity}`])
                  )
                )
              ]);

              this.set.loadingSuggestions(false);
            } catch (error) {
              if (error) {
                console.error(error);
              }
            }
          }, 300);
        }
      }
    );

    let debuffResults;
    // get search results
    reaction(
      () =>
        JSON.stringify({
          ...this.queryVariables,
          type: this.filterType,
          query: this.searchParamsQ,
          tag_list: this.filterTaskArray
        }),
      () => {
        clearTimeout(debuffResults);

        if (this.filterType) {
          this.set.results();
          this.set.loadingResults(true);

          debuffResults = setTimeout(async () => {
            try {
              const query = this.searchParamsQ || this.queryVariables.query;
              const entity =
                this.filterType[0].toUpperCase() + this.filterType.slice(1);
              const results =
                entity === "Papers"
                  ? await this.searchPapers({ query, limit: 50 })
                  : await this.query({
                      query,
                      gqlQuery: `search${entity}${
                        entity === "Models" && this.hasQuery === false
                          ? "NoQuery"
                          : ""
                      }`,
                      resolveFunction: results =>
                        Object.values(results?.data ?? {})[0] ?? [],
                      limit: 100
                    });

              this.set.results(results);
              this.set.loadingResults(false);
            } catch (error) {
              if (error) {
                console.error(error);
              }
            }
          }, 300);
        }
      }
    );

    let debuffCount;
    // get counts
    reaction(
      () => this._.user.loaded && this.queryVariables,
      () => {
        clearTimeout(debuffCount);
        this.set.loadingCount(true);

        debuffCount = setTimeout(async () => {
          const count = {};

          await Promise.allSettled(
            ["Papers", "Tasks", "Videos", "Models"].map(entity =>
              this.query({
                gqlQuery: `search${entity}Count`,
                query: this.queryVariables.query
              }).then(result => {
                count[entity.toLowerCase()] = result?.[0]?.count;

                this.set.count({ ...count });
              })
            )
          );

          this.set.loadingCount(false);
        }, 300);
      }
    );
  }
  set = {
    userInput: (userInput = "") => {
      this.userInput = userInput;
    },
    searchParams: (searchParams = new URLSearchParams()) => {
      this.searchParams = searchParams;
    },
    filterSort: filterSort => {
      this.filterSort = filterSort;
    },
    filterTime: filterTime => {
      this.filterTime = filterTime;
    },
    filterTask: (filterTask = new Set()) => {
      if (this.filterTask?.size !== 0 || filterTask.size !== 0) {
        this.filterTask = filterTask;
      }
    },
    filterType: filterType => {
      this.filterType = filterType;
    },
    filterPublisher: filterPublisher => {
      this.filterPublisher = filterPublisher;
    },
    filterStars: filterStars => {
      this.filterStars = filterStars;
    },
    isFocused: (isFocused = false) => {
      this.isFocused = isFocused;
    },
    results: (results = []) => {
      this.results = results;
    },
    suggestedPapers: (suggestedPapers = []) => {
      this.suggestedPapers = suggestedPapers;
    },
    suggestedModels: (suggestedModels = []) => {
      this.suggestedModels = suggestedModels;
    },
    suggestedVideos: (suggestedVideos = []) => {
      this.suggestedVideos = suggestedVideos;
    },
    suggestedTasks: (suggestedTasks = []) => {
      this.suggestedTasks = suggestedTasks;
    },
    suggestedEntityInFocus: suggestedEntityInFocus => {
      this.suggestedEntityInFocus = suggestedEntityInFocus;
    },
    count: (count = {}) => {
      this.count = count;
    },
    loadingResults: (loadingResults = false) => {
      this.loadingResults = loadingResults;
    },
    loadingSuggestions: (loadingSuggestions = false) => {
      this.loadingSuggestions = loadingSuggestions;
    },
    loadingCount: (loadingCount = true) => {
      this.loadingCount = loadingCount;
    }
  };
  // computed
  get showSuggestions() {
    return this.isFocused !== false; //|| true;
  }
  get suggestedEntitiesCount() {
    const entities = [
      this.suggestedModels,
      this.suggestedPapers,
      this.suggestedTasks,
      this.suggestedVideos
    ];
    let count = 0;

    for (const entity of entities) {
      count += entity.length === 0 ? 0 : 1;
    }

    return count;
  }
  get queryVariables() {
    const acceptedTimes = new Set(["1", "2", "3", "5", "10"]);

    return {
      query: this.userInput.trim() || undefined,
      tag_list: this.filterTaskArray,
      publisher: this.filterPublisher,
      sortBy: this.filterSort,
      filterStars: this.filterStars,
      time: acceptedTimes.has(this.filterTime)
        ? `P${this.filterTime}Y`
        : undefined
    };
  }
  get hasQuery() {
    return this.userInput !== "";
  }
  get hasFilters() {
    return (
      this.filterStars !== undefined ||
      this.filterSort !== undefined ||
      this.filterPublisher !== undefined ||
      this.filterTime !== undefined ||
      this.filterTask.size !== 0
    );
  }
  get filterTaskArray() {
    return [...this.filterTask];
  }
  get saves() {
    const allowedEntities = new Set(["paper", "video", "tag"]);
    const saves = {};
    const folderName = this.filterStars;

    if (folderName) {
      const folder = this._.stars.dictionary[folderName] ?? [
        ...this._.stars.map.values()
      ];

      for (const { type, id } of folder) {
        if (allowedEntities.has(type)) {
          saves[`${type}s`] ??= [];
          saves[`${type}s`].push(id);
        }
      }
    }

    return Object.entries(saves);
  }
  get searchParamsQ() {
    return this.searchParams.get("q")?.trim() || "";
  }
  get suggestions() {
    const suggestions = [
      ...this.suggestedModels
        .slice(0, 3)
        .map(repo =>
          repo.models ? { ...repo.models[0], __typename: "model" } : repo
        ),
      ...this.suggestedTasks.slice(0, 2),
      ...this.suggestedVideos.slice(0, 2)
    ];
    const papers = this.suggestedPapers;

    for (const paper of papers) {
      if (suggestions.length === 9) {
        break;
      }
      suggestions.push(paper);
    }

    return suggestions;
  }
  get queryVariablesString() {
    return JSON.stringify(this.queryVariables);
  }
  query = ({ gqlQuery, limit = 10 }) =>
    new Promise((resolve, reject) => {
      const queryThatKickedOff = this.queryVariablesString;

      this.watchQuery(
        {
          query: this._.gql.get(gqlQuery),
          variables: { ...this.queryVariables, limit }
        },
        response => {
          if (queryThatKickedOff === this.queryVariablesString) {
            resolve(Object.values(response?.data ?? {})[0] ?? []);
          } else {
            reject();
          }
        },
        reject
      );
    });

  updateUrlParams = (router, urlParams, overwrite = false) => {
    const url = new URLSearchParams(overwrite ? urlParams : this.searchParams);

    for (const param in urlParams) {
      url.delete(param);
      urlParams[param]?.forEach(value => url.append(param, value));
    }

    const queryString = url.toString();

    router.push(`/search${queryString ? `?${queryString}` : ""}`);
  };
  vectorSearch = (query, limit = 10) =>
    new Promise(async (resolve, reject) => {
      const res = await fetch("/api/search", {
        method: "POST",
        body: JSON.stringify({ text: query })
      });
      const { vector } = await res.json();

      // in case we vectorization failed, return no vector results
      if (vector === undefined) {
        return resolve([]);
      }

      const queryThatKickedOff = this.queryVariablesString;

      this.watchQuery(
        {
          variables: { ...this.queryVariables, vector, limit },
          query: this._.gql.get("searchPapers2_vector")
        },
        results => {
          if (queryThatKickedOff === this.queryVariablesString) {
            resolve(Object.values(results?.data ?? {})[0]);
          } else {
            reject();
          }
        },
        reject
      );
    });
  async searchPapers({ query, limit }) {
    const searches = await Promise.all([
      this.query({
        gqlQuery:
          query === undefined ? "searchPapers2_no_vector" : "searchPapers",
        limit
      }),
      // cannot use vector search if not supported and trying to search without query
      query === undefined ? [] : this.vectorSearch(query, limit)
    ]);
    const results = [];

    for (
      let set = new Set(),
        i = 0,
        j = Math.max(searches[0]?.length, searches[1]?.length);
      i < j;
      i++
    ) {
      const paperFuzzy = searches[0][i];
      const paperVector = searches[1][i];

      if (paperFuzzy && set.has(paperFuzzy.id) === false) {
        set.add(paperFuzzy.id);
        results.push(paperFuzzy);
      }
      if (paperVector && set.has(paperVector.id) === false) {
        set.add(paperVector.id);
        results.push(paperVector);
      }
    }

    return results;
  }
}
